import SplunkOtelWeb from "@splunk/otel-web";
// Expose to the main bundle via the window
// FROM https://docs.splunk.com/Observability/gdi/get-data-in/rum/browser/instrument-single-page-applications.html#next-js

const prodRegex = /^(?:www\.)?renttherunway\.com/gm;
const stageRegex = /^.*stage.*\.renttherunway\.com/gm;
const qaRegex = /^.*qa.*\.renttherunway\.com/gm;
const devRegex = /localhost/gm;

if (process.env.NEXT_PUBLIC_RUM_ENABLED) {
  window.SplunkRum = SplunkOtelWeb;
  SplunkOtelWeb.init({
    beaconUrl: `https://rum-ingest.${process.env.NEXT_PUBLIC_RUM_REALM}.signalfx.com/v1/rum`,
    rumAuth: process.env.NEXT_PUBLIC_RUM_AUTH,
    app: `storefront`,
    cookieDomain: `.renttherunway.com`,
    environment: window.RTR?.config?.environment || determineEnvironment(),
    _experimental_allSpansExtendSession: true,
    // ignoreUrls for signalfx.com and RUM
    ignoreUrls: [/.*signalfx.com.*/],
    disableBots: true,
    instrumentations: {
      longtask: false,
    },
  });

  window.dispatchEvent(new CustomEvent("splunkRumLoadEvent"));
}

function determineEnvironment() {
  const host = window?.location?.host;
  if (!host) {
    return "unknown";
  }
  if (prodRegex.test(host)) {
    return "production";
  } else if (stageRegex.test(host)) {
    return "stage";
  } else if (qaRegex.test(host)) {
    return "qa";
  } else if (devRegex.test(host)) {
    return "development";
  } else {
    return "unknown";
  }
}
